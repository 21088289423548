
import { IPackage } from "@/interfaces/mlm";
import { readActivePackage } from "@/store/mlm/getters";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class MyPromocodeModal extends Vue {
  // @ts-ignore
  @Prop({ required: true, default: false }) public readonly modalShow: boolean;
  // @ts-ignore
  // @Prop({required: true}) readonly public package: IPackage;
  public loadingState: any = {
    submit: false,
  };

  public closeModal() {
    this.$emit("close-modal");
  }
  get activePackage() {
    return readActivePackage(this.$store);
  }
}
