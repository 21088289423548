
import { Component, Vue, Prop } from "vue-property-decorator";
import { dispAuthFetch } from "@/store/mlm/actions";
import ProductInfoModal from "./ProductInfoModal.vue";

interface InvoiceResponse {
  invoice: {
    id: number;
    status: string;
    amount: string;
    currency: string;
    point_volume: string;
    payment_info: string;
    error_text: string;
    created_at: string;
    processed_datetime: string | null;
    comment_template: string | null;
    comment_params: any;
    order: number;
  };
  qr_code: string;
  address: string;
  crypto_amount: string;
}

@Component({
  components: {
    ProductInfoModal,
  },
})
export default class ProductPurchaseModal extends Vue {
  @Prop({ required: true }) readonly modalShow!: boolean;
  @Prop({ required: true }) readonly product!: any;

  public infoModalShow: boolean = false;

  public currentStage: number = 0;
  public invoiceResponse: InvoiceResponse | null = null;
  public paymentInfo = {
    id: 0,
    name: "",
    surname: "",
    phone: "",
    "tg/wassup": "",
  };

  public formErrors: Record<string, string> = {};

  public validateForm(): boolean {
    this.formErrors = {};
    let isValid = true;

    if (!this.paymentInfo.name.trim()) {
      this.formErrors.name = this.$t("Name is required").toString();
      isValid = false;
    }

    if (!this.paymentInfo.surname.trim()) {
      this.formErrors.surname = this.$t("Surname is required").toString();
      isValid = false;
    }

    if (!this.paymentInfo.phone.trim()) {
      this.formErrors.phone = this.$t("Phone number is required").toString();
      isValid = false;
    }

    return isValid;
  }

  public async handlePurchase() {
    const invoiceResp = await dispAuthFetch(this.$store, {
      input: "/api/v1/mlm/product/purchase/request/",
      init: {
        method: "POST",
        body: {
          standart: "BEP20",
          token: "USDT",
          product_name: this.product.name,
        },
      },
    });

    if (invoiceResp.status === 200) {
      this.invoiceResponse = await invoiceResp.json();
      this.currentStage = 1;
    } else {
      this.$toast.error("Failed to create invoice, please try again later");
    }
  }

  public async confirmPurchase() {
    if (!this.invoiceResponse) return;

    if (!this.validateForm()) {
      this.$toast.error(
        this.$t("Please fill in all required fields").toString()
      );
      return;
    }

    const confirmResponse = await dispAuthFetch(this.$store, {
      input: "/api/v1/mlm/product/purchase/confirm/",
      init: {
        method: "POST",
        body: {
          payment_info: this.paymentInfo,
          invoice: this.invoiceResponse.invoice.id,
          product_name: this.product.name,
        },
      },
    });

    if (confirmResponse.status === 200) {
      const confirmData = await confirmResponse.json();
      this.invoiceResponse.invoice = confirmData;
      this.currentStage = 2;
    } else {
      this.$toast.error("Failed to confirm purchase, please try again later");
    }
  }

  public closeModal() {
    this.currentStage = 0;
    this.invoiceResponse = null;
    this.$emit("close-modal");
  }

  public copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      this.$toast.success("Copied to clipboard");
    });
  }
}
