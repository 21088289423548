
import { dispatchUpdateUserAvatar, dispatchUpdateUserProfile } from '@/store/main/actions';
import { readUserProfile } from '@/store/main/getters';
import { readActivePackage } from '@/store/mlm/getters';
import { Component, Vue , Prop} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class ProvideProfileDetailsModal extends Vue {
  // @ts-ignore
  @Prop({required: true, default: false}) readonly public modalShow: boolean;
  public loadingState: any = {
    submit: false,
  };
  public avatarModalShow: boolean = false;
  public fName: string = '';
  public lName: string = '';
  public telegram: string = '';

  public handleLoadAvatar() {
    if (this.$refs.myFileInput) {
      // @ts-ignore
      this.$refs.myFileInput.click();
    }
  }
  public async handleChangeImage(event) {
    const formData = new FormData();
    formData.append('avatar', event.target.files ? event.target.files[0] : '');
    await dispatchUpdateUserAvatar(this.$store, formData);
  }

  public async handleSubmitCredentials() {
    const result = await dispatchUpdateUserProfile(this.$store, {
      first_name: this.fName, last_name: this.lName, tg_username: this.telegram
    });
    if (result) {
      this.$toast.success(this.$t('Saved successfully').toString());
      this.closeModal();
    } else {
      this.$toast.error(this.$t('Failed to save').toString());
    }
  }

  public closeModal() {
    this.$emit('close-modal');
  }
  get activePackage() {
    return readActivePackage(this.$store);
  }
  get userProfile() {
    return readUserProfile(this.$store);
  }
}
