
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class WinterPromoModal extends Vue {
  @Prop({ required: true, default: false }) public readonly modalShow!: boolean;

  public closeModal() {
    this.$emit("close-modal");
  }
}
