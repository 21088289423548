
import { dispatchSubmitActivatePromocode } from '@/store/mlm/actions';
import { Component, Vue , Prop} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ActivatePromocode extends Vue {
  // @ts-ignore
  @Prop({required: true, default: false}) readonly public modalShow: boolean;
  
  public promocode: string = '';
  public loadingState: any = {
    submit: false,
  };

  public async handleActivate() {
    this.loadingState.submit = true;
    const result = await dispatchSubmitActivatePromocode(this.$store, {promocode: this.promocode});
    if (result) {
      // @ts-ignore
      this.$toast.success(this.$t('Promo code activated successfully'), {
        duration: 3000, position: 'bottom',
      });
      this.closeModal(true);
    }
    this.loadingState.submit = false;
  }

  public closeModal(updated: boolean = false) {
    this.$emit('close-modal', updated);
  }
  
}
