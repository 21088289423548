
import { Component, Vue , Prop} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class DexPromoDetailModal extends Vue {
  // @ts-ignore
  @Prop({required: true, default: false}) readonly public modalShow: boolean;

  
  public closeModal() {
    this.$emit('close-modal');
  }
}
