
import { Component, Vue , Prop} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class MaintenanceModal extends Vue {
  // @ts-ignore
  @Prop({required: true, default: false}) readonly public modalShow: boolean;
  public loadingState: any = {
    submit: false,
  };

  public closeModal() {
    this.$emit('close-modal');
  }
}
